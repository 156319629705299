define('frontend/models/biblio', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    origin: _emberData.default.attr('string'),
    isbn: _emberData.default.attr('string'),
    edition: _emberData.default.attr('string'),
    canBeQueued: _emberData.default.attr('boolean'),
    hasItemLevelQueue: _emberData.default.attr('boolean'),
    recordType: _emberData.default.attr('string'),
    noInQueue: _emberData.default.attr('number'),
    items: _emberData.default.hasMany('item'),
    subscriptiongroups: _emberData.default.hasMany('subscriptiongroup'),
    hasEnum: _emberData.default.attr('boolean'),
    biblioCallNumber: _emberData.default.attr('string'),
    hasAvailableKursbok: _emberData.default.attr('boolean')
  });
});