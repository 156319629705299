define('frontend/routes/request/order', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    queryParams: {
      location: {
        replace: true
      },
      type: {
        replace: true
      }
    },

    model: function model() {
      var biblio = this.modelFor('request').biblio;
      var user = this.modelFor('request').user;

      return Ember.RSVP.hash({
        locations: this.get('store').findAll('location'),
        loantypes: this.get('store').findAll('loanType'),
        reserve: this.get('store').createRecord('reserve', {
          biblio: biblio,
          user: user
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },


    actions: {
      goBack: function goBack() {
        window.history.back();
      },
      submitOrder: function submitOrder() {
        var _this = this;

        this.controllerFor('request.order.summary').set('btnSubmitOrderDisabled', true);
        this.controller.get('model.reserve').save().then(function () {
          _this.transitionTo('request.order.confirmation');
        }, function (error) {
          _this.get('controller').set('errors', error.errors);
          _this.transitionTo('request.order.confirmation');
        });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var reserve = controller.get('model.reserve');
        if (reserve.get('isNew')) {
          reserve.destroyRecord();
        }
      }
    }
  });
});