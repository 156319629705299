define('frontend/routes/request/order/summary', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		actions: {
			moveForward: function moveForward() {
				this.transitionTo('request.order.confirmation');
			}
		}
	});
});