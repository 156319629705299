define('frontend/controllers/request/order/items', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    request: Ember.inject.controller(),
    order: Ember.inject.controller('request.order'),
    activeTabName: null,
    isReservedClick: false,
    isReservedClicked: Ember.observer('isReservedClick', function () {
      // deal with the change
      this.get('order.model.reserve').set('isReservedClicked', this.get('isReservedClick'));
    }),
    numSubscriptions: Ember.computed('request.model.biblio.subscriptiongroups', function () {
      var res = 0;
      var grps = this.get('request.model.biblio.subscriptiongroups');
      grps.map(function (obj) {
        res += obj.get('subscriptions.length');
      });
      return res;
    }),

    activeTab: Ember.computed('request.model.biblio.subscriptiongroups', {
      get: function get() {
        if (this.get("request.model.biblio.hasItemLevelQueue")) {
          if (this.get("request.model.biblio.subscriptiongroups.length")) {
            this.set("activeTabName", "tab1");
          } else {
            this.set("activeTabName", "tab2");
          }
        } else {
          this.set("activeTabName", "tab1");
        }
        return this.get("activeTabName");
      },
      set: function set(key, value) {
        var activeName = value;
        this.set('activeTabName', activeName);
        return value;
      }
    }),

    showMyLoanMessage: Ember.computed(function () {
      return this.get('request.view') !== '46GUB_KOHA';
    }),

    hasItemAvailableForOrder: Ember.computed('request.model.biblio.items', function () {
      if (this.get("itemsAvailableForOrder.length") > 0) {
        return true;
      }
      return false;
    }),

    itemsAvailable: Ember.computed('request.model.biblio.items', function () {
      return this.get("request.model.biblio.items").filter(function (item) {
        return item.get("isAvailible");
      });
    }),

    itemsAvailableForOrder: Ember.computed('request.model.biblio.items', function () {
      return this.get("request.model.biblio.items").filter(function (item) {
        return item.get("canBeOrdered");
      });
    }),

    itemsNotAvailable: Ember.computed('request.model.biblio.items', function () {
      return this.get("request.model.biblio.items").filter(function (item) {
        return !item.get("isAvailible");
      });
    }),
    actions: {
      setItemToOrder: function setItemToOrder(item, isReservedClicked) {
        if (isReservedClicked) {
          this.set('isReservedClick', true);
        }
        this.get('order.model.reserve').set('item', item);
        this.get('order.model.reserve').set('subscription', null);
        this.get('order.model.reserve').set('subscriptionNotes', null);
        this.transitionToRoute('request.order.details');
      },
      setSubscriptionToOrder: function setSubscriptionToOrder(subscription) {
        this.get('order.model.reserve').set('subscription', subscription);
        this.get('order.model.reserve').set('item', null);
        this.get('order.model.reserve').set('reserveNotes', null);

        this.transitionToRoute('request.order.details');
      },
      setActiveTab: function setActiveTab(tab) {
        this.set('activeTab', tab);
      }
    }

  });
});