define("frontend/routes/request", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var ticket = transition.queryParams.ticket;
      var biblioId = transition.params.request.id;
      var SSOscanner = transition.queryParams.SSOscanner;

      if (biblioId === "error") {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          reject({ errors: { errors: [{ "code": 'NO_ID', "detail": "loreum" }] } });
        });
      }

      if (ticket) {
        this.get('store').createRecord('tmp_biblio', { id: 1, biblio: biblioId });
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('session').authenticate('authenticator:cas', {
            cas_ticket: ticket,
            cas_service: _this.returnUrl(biblioId, SSOscanner)
          }).then(function () {
            // If this session is only used in the iframe to scan for successful SSO,
            // do not resolve the promise
            if (SSOscanner) {
              localStorage.setItem('login-check', 'inner-login-ok');
              // @FIXME: 'nope' value is never checked against elsewhere in the code?
              localStorage.setItem('logged-in-ok', 'nope');
            } else {
              resolve();
            }
          }, function (reasons) {
            reject({ errors: { errors: [reasons.errors] } });
          });
        });
      }
    },
    model: function model(params) {
      if (this.get('session.isAuthenticated')) {
        return Ember.RSVP.hash({
          //@FIXME: this.get('store')?
          biblio: this.store.findRecord('biblio', params.id, { adapterOptions: { items_on_subscriptions: "true" } }),
          user: this.store.queryRecord('user', { biblio: params.id })
        });
      } else {
        return Ember.RSVP.hash({
          biblio: this.store.find('biblio', params.id)
        });
      }
    },
    afterModel: function afterModel(model, transition) {
      var ticket = transition.queryParams.ticket;
      if (!ticket) {
        var token = this.store.peekRecord('token', 1);
        if (!token) {
          this.controllerFor('request').set('goToLogin', true);
        }
      } else {
        // There is a ticket, login was successful.
        localStorage.setItem('login-check', 'inner-login-ok');
        localStorage.setItem('logged-in-ok', 'nope');
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('ticket', null);
      controller.set('model', model);

      if (controller.get('goToLogin')) {
        Ember.run.later(function () {
          controller.set('goToLogin', false);
        });
        this.transitionTo('request.login');
      } else {
        this.replaceWith('request.order.items');
      }
      controller.set('forceSSO', null);
      controller.set('SSOscanner', null);
    },
    returnUrl: function returnUrl(id, SSOscanner) {
      var baseUrl = window.location.origin;
      var routeName = this.get('routeName');
      var routeUrl = this.router.generate(routeName, { id: id });
      if (SSOscanner) {
        routeUrl += '?SSOscanner=true';
      }
      return baseUrl + routeUrl;
    },
    casLoginUrl: function casLoginUrl() {
      return this.get('store').peekRecord('config', 1).get('casurl') + '/login';
    }
  });
});