define('frontend/routes/request/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params, transition) {
      var biblioId = transition.params.request.id;
      this.get('store').createRecord('tmp_biblio', { id: 1, biblio: biblioId });

      return new Ember.RSVP.Promise(function (resolve) {
        localStorage.removeItem('login-check');
        resolve();
      });
    },
    setupController: function setupController(controller) {
      controller.set('showForm', true);
    },

    returnUrl: function returnUrl(id) {
      var baseUrl = window.location.origin;
      var routeUrl = this.router.generate('request', { id: id }, { queryParams: { SSOscanner: null } });
      return baseUrl + routeUrl;
    },
    casLoginUrl: function casLoginUrl() {
      return this.get('store').peekRecord('config', 1).get('casurl') + '/login';
    }
  });
});