define('frontend/controllers/request', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['ticket', 'forceSSO', 'SSOscanner', 'view'],
    ticket: null,
    forceSSO: null,
    SSOscanner: null,
    view: null
  });
});