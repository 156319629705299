define('frontend/controllers/request/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    request: Ember.inject.controller(),
    showForm: false,
    getLocale: Ember.computed('i18n.locale', 'i18n.locales', function () {
      return this.get('i18n.locale');
    }),

    actions: {
      login: function login() {
        var _this = this;

        var _getProperties = this.getProperties('username', 'password'),
            username = _getProperties.username,
            password = _getProperties.password;

        this.get('session').authenticate('authenticator:cas', { username: username, password: password }).catch(function (reason) {
          _this.set('errorMessage', reason.error || reason);
        });
      }
    },

    inputAutocomplete: Ember.computed(function () {
      return this.get('request.view') !== '46GUB_KOHA';
    }),
    showCasLogin: Ember.computed(function () {
      return this.get('request.view') !== '46GUB_KOHA';
    }),
    libraryCardUrl: Ember.computed(function () {
      var lang = this.get('getLocale');
      return this.get('store').peekRecord('config', 1).get('registrationurl') + '?lang=' + lang;
    }),
    casLoginUrl: Ember.computed(function () {
      return this.get('store').peekRecord('config', 1).get('casurl') + '/login';
    }),
    returnUrl: Ember.computed('request.model.biblio.id', function () {
      var id = this.get('request.model.biblio.id');
      var baseUrl = window.location.origin;
      var routeUrl = this.target.router.generate('request', { id: id }, { queryParams: { SSOscanner: null } });
      return baseUrl + routeUrl;
    }),
    scannerReturnUrl: Ember.computed('request.model.biblio.id', function () {
      var id = this.get('request.model.biblio.id');
      var baseUrl = window.location.origin;
      var routeUrl = this.target.router.generate('request', { id: id }, { queryParams: { SSOscanner: true } });
      return baseUrl + routeUrl;
    }),
    casUrl: Ember.computed('casLoginUrl', 'returnUrl', function () {
      var url = this.get('casLoginUrl') + '?' + Ember.$.param({ service: this.get('returnUrl') });
      return url;
    }),
    scannerCasUrl: Ember.computed('casLoginUrl', 'scannerReturnUrl', function () {
      var url = this.get('casLoginUrl') + '?' + Ember.$.param({ service: this.get('scannerReturnUrl') });
      return url;
    })

  });
});