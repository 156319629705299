define('frontend/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var token = this.store.peekRecord('token', 1);
      if (token) {
        var biblioId = this.store.peekRecord('tmp_biblio', 1).get('biblio');
        if (biblioId) {
          this.replaceWith('request', biblioId, { queryParams: { ticket: null } });
        }
      }
    }
  });
});