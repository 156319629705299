define('frontend/controllers/application', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		i18n: Ember.inject.service(),
		session: Ember.inject.service(),
		queryParams: ['lang'],
		lang: 'sv',
		// ticket: null,

		getLocale: Ember.computed('i18n.locale', 'i18n.locales', function () {
			return this.get('i18n.locale');
		})

	});
});