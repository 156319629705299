define('frontend/controllers/request/order', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		i18n: Ember.inject.service(),
		errors: null

	});
});