define('frontend/components/pick-type-of-loan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    filteredLoanTypes: Ember.computed('loanTypes', 'item.{itemType,notForLoan}', function () {

      var itemType = this.get('item.itemType');
      var notForLoan = this.get('item.notForLoan');
      var loanTypes = this.get('loanTypes');

      loanTypes.map(function (type) {
        type.set('disabled', false);
        if (type.id == 1) {
          if (itemType == '8' || itemType == '17' || notForLoan == '-3') {
            type.set('disabled', true);
          }
        }
      });

      return loanTypes;
    }),

    didInsertElement: function didInsertElement() {
      // First get the filtered list
      var filteredLoanTypes = this.get('filteredLoanTypes');
      var selectedItem = this.get('selectedItem');
      var filteredFromDisabledLoanTypes = filteredLoanTypes.filter(function (item) {
        if (!item.disabled) {
          return item;
        }
      });
      var defaultValue = filteredFromDisabledLoanTypes.get('firstObject').id;

      // Check if anything is selected
      if (selectedItem) {
        // Then check if what is selected is in the filtered list, and if so, keep that selected
        if (filteredLoanTypes.findBy('id', selectedItem)) {
          return;
        }
      }
      // If nothing is selected, or what is selected is not in the list, set a new default value
      this.get('onSelect')(defaultValue);
    }
  });
});