define('frontend/models/loan-type', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		i18n: Ember.inject.service(),
		nameSv: _emberData.default.attr(),
		nameEn: _emberData.default.attr(),

		name: Ember.computed('i18n.locale', function () {
			if (this.get("i18n.locale") === "sv") {
				return this.get("nameSv");
			}
			return this.get("nameEn");
		})
	});
});